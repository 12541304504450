import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Img from 'gatsby-image'
const useStyles = makeStyles(theme => ({
  paddingY: {
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem 0rem",
    },
  },
  image: {
    width: "100%",
    borderRadius: 10,
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
  },
}))

const ImageCard = ({ image, text }) => {
  const classes = useStyles()

  return (
    <div className={classes.paddingY}>
      <Img fluid={image} alt={text} className={classes.image} />
    </div>
  )
}

export default ImageCard

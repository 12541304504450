import React from "react"
import Layout from "../../components/Layout/layout"
import { Container } from "@material-ui/core"
import PageInfoSection from "../../components/PageInfoSection/PageInfoSection"
import ImageCard from '../../components/PageInfoSection/components/ImageCard'
import image from '../../assets/images/seo.jpeg'
import Squares from '../../components/Squares/Squares'
import { graphql, useStaticQuery } from 'gatsby'

const SeoPage = () => {
    const { seo } = useStaticQuery(graphql`
  query seo {
    seo: file(relativePath: {eq: "seo.jpeg"}) {
      childImageSharp {
        fluid {
          src
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (
    <Layout>
      <Squares>
        <Container>
          <PageInfoSection
            title={`search engine`}
            blueTitle="optimization"
            text="Our clients receive the best quality and highest frequency of website traffic in their respective industries. We will help evolve your organization into a major online presence with the cutting edge content that consumers crave. "
          >
            <ImageCard image={seo.childImageSharp.fluid} text='Search Engine Optimization' />
          </PageInfoSection>
        </Container>
      </Squares>
    </Layout>
  )
}

export default SeoPage
